<template>
  <div
    class="flex flex-col gap-y-4 border border-gray-300"
    v-if="!hideComponent && articles && articles.length > 0"
  >
    <NuxtLink
      :to="{
        name: 'sponsored_id',
        params: {
          id: article.urlKey ?? article.id,
        },
      }"
      v-for="article in articles"
      class="py-6 px-6 @container bg-[#F3F5F7] font-[Georgia] flex justify-between"
    >
      <div class="flex flex-col gap-2">
        <h2 class="font-bold" :class="{ 'text-2xl': !compact }">
          {{ article.headline }}
        </h2>
        <span class="text-blue font-bold text-sm"
          >{{ $t('ads.nativeAds1') }} - {{ article.customWriter }}</span
        >
      </div>
      <NuxtImg
        v-if="!compact && getImageUrlFromArticle(article)"
        :src="getImageUrlFromArticle(article)"
        :width="300"
        :height="170"
        class="hidden rounded-md object-cover sm:block w-1/3 md:w-[156px] md:h-25"
      />
    </NuxtLink>
  </div>
</template>
<script setup lang="ts">
import type { ArticleDto } from '~/typesAuto/apicore/v1'

const nuxtApp = useNuxtApp()

const indexStore = useIndexStore()

const props = withDefaults(
  defineProps<{
    article?: ArticleDto
    compact?: boolean
    top?: number
    offset?: number
  }>(),
  {
    top: 1,
    offset: 0,
  }
)

const { data } = await useAsyncData(
  `article-sponsored-teaser-top-${props.top}-offset-${props.offset}`,
  async () => {
    if (props.article) return
    const articles = await nuxtApp.$api.articles.getSponsoredArticles({
      paperId: indexStore.currentPaperIdentifier,
      $top: props.top + props.offset,
      offset: props.offset,
    })
    return {
      ...articles,
      data: articles.data.slice(0, 1),
    }
  }
)
const hideComponent = computed(() => {
  const someTeaserIsInvalid = articles.value?.some(
    (article) => !article.customWriter
  )
  return !props.article?.customWriter && someTeaserIsInvalid
})
const articles = computed(() => {
  return props.article ? [props.article] : data.value?.data
})
function getImageUrlFromArticle(article: ArticleDto): string | undefined {
  if (!article.images) {
    return
  }
  return article.images[0]?.url ?? undefined
}
</script>
